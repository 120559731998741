// eslint-disable-next-line no-restricted-syntax
import { Container, DisplayObject } from 'pixi.js';
import { Disposable } from '../interfaces/disposable';
import { lerp } from '../math/interpolation';
import { Rect } from '../math/rectangle';

export function calculateScaleFactor(baseResolution: Rect, resolution: Rect, heightWidthRatio = 0.5): number {
  const logWidth = Math.log2(resolution.width / baseResolution.width);
  const logHeight = Math.log2(resolution.height / baseResolution.height);
  const logWeightedAverage = lerp(logHeight, logWidth, heightWidthRatio);

  return 1 / Math.pow(2, logWeightedAverage);
}

export interface Rescalable {
  rescale(scaleFactor: number): void;
}

const isRescalable = (o: any): o is Rescalable => 'rescale' in o;

export class DisplayScaler implements Disposable {
  root: Container;
  scaleFactor = 1;
  baseResolution: Rect;
  rescalables: Array<Rescalable> = [];
  observer: DisplayListObserver;
  heightWidthRatio: number;

  constructor(root: Container, baseResolution: Rect, heightWidthRatio = 0.5) {
    this.root = root;
    this.baseResolution = baseResolution;
    this.heightWidthRatio = heightWidthRatio;
    this.observer = new DisplayListObserver(root, this.onChildAdded.bind(this), this.onChildRemoved.bind(this));
  }
  onChildAdded = (child: DisplayObject) => {
    if (isRescalable(child)) {
      this.add(child);
    }
  };
  onChildRemoved = (child: DisplayObject) => {
    if (isRescalable(child)) {
      this.remove(child);
    }
    if (child instanceof Container)
      for (const child2 of (child as Container).children) {
        if (isRescalable(child2)) {
          this.onChildRemoved(child2);
        }
      }
  };

  add(rescalable: Rescalable) {
    if (this.rescalables.includes(rescalable)) throw new Error('Rescalable already added');
    rescalable.rescale(this.scaleFactor);
    this.rescalables.push(rescalable);
  }

  remove(rescalable: Rescalable) {
    const index = this.rescalables.indexOf(rescalable);
    if (index >= 0) {
      this.rescalables.splice(index, 1);
    }
  }

  dispose(): void {
    this.observer.dispose();
  }

  resize(screen: Rect) {
    this.scaleFactor = calculateScaleFactor(screen, this.baseResolution, this.heightWidthRatio);
    for (const rescalable of this.rescalables) {
      rescalable.rescale(this.scaleFactor);
    }
  }
}

function traverseDisplayList(root: Container, callback: (child: DisplayObject) => void) {
  for (const child of root.children) {
    callback(child);
    if (child instanceof Container) {
      traverseDisplayList(child, callback);
    }
  }
}

// create a DisplayListObserver to listen to changes in the display list recursively
export class DisplayListObserver implements Disposable {
  root: Container;
  onAdded: ((child: DisplayObject) => void) | undefined;
  onRemoved: ((child: DisplayObject) => void) | undefined;

  constructor(root: Container, onAdded?: (child: DisplayObject) => void, onRemoved?: (child: DisplayObject) => void) {
    this.root = root;
    this.root.on('childAdded', this.onChildAdded, this);
    this.root.on('childRemoved', this.onChildRemoved, this);
    this.onAdded = onAdded;
    this.onRemoved = onRemoved;
  }

  dispose(): void {
    this.root.off('childAdded', this.onChildAdded, this);
    this.root.off('childRemoved', this.onChildRemoved, this);
    traverseDisplayList(this.root, (child) => {
      if (child instanceof Container) {
        child.off('childAdded', this.onChildAdded, this);
        child.off('childRemoved', this.onChildRemoved, this);
      }
    });
  }

  onChildAdded = (child: DisplayObject) => {
    this.onAdded?.(child);
    if (child instanceof Container) {
      child.on('childAdded', this.onChildAdded, this);
      child.on('childRemoved', this.onChildRemoved, this);
      for (const existingChild of child.children) {
        this.onChildAdded(existingChild);
      }
    }
  };

  onChildRemoved = (child: DisplayObject) => {
    this.onRemoved?.(child);
    if (child instanceof Container) {
      child.off('childAdded', this.onChildAdded, this);
      child.off('childRemoved', this.onChildRemoved, this);
    }
  };
}
