// eslint-disable-next-line no-restricted-syntax
import { Container, DisplayObject } from 'pixi.js';
import { isTickable, Tickable } from './interfaces/tickable';
import { DisplayListObserver } from '../core/pixi/display-scaler';

export class TickableManager implements Tickable {
  tick(delta: number): void {
    for (const tickable of this.tickables) {
      tickable.tick(delta);
    }
  }
  private tickables: Tickable[] = [];

  public add(tickable: Tickable) {
    this.tickables.push(tickable);
  }

  public remove(tickable: Tickable) {
    const index = this.tickables.indexOf(tickable);
    if (index >= 0) {
      this.tickables.splice(index, 1);
    }
  }
}

export class DisplayListTickableObserver {
  constructor(private stage: Container, private tickableManager: TickableManager) {
    new DisplayListObserver(this.stage, this.onChildAdded.bind(this), this.onChildRemoved.bind(this));
  }

  private onChildAdded(child: DisplayObject) {
    if (isTickable(child)) this.tickableManager.add(child);
  }

  private onChildRemoved(child: DisplayObject) {
    if (isTickable(child)) this.tickableManager.remove(child);
  }
}
